.globalWrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  height: 100vh;
}

.routerWrapper {
    margin: 20px 20px 20px 0px;
    border: 1px solid var(--semanticColorsBodyDivider);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.navigationWrapper {
  position: relative;
  min-width: 220px;
  border: 1px solid var(--semanticColorsBodyDivider);
  margin: 20px;
}

@media screen and (max-width: 640px) {
  .globalWrapper {
    flex-direction: column;
    height: auto;
  }

  .routerWrapper {
    margin: 0px;
    width: auto;
  }

  .navigationWrapper {
    margin: 0px;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--paletteNeutralQuaternaryAlt);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--paletteNeutralTertiaryAlt);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--paletteNeutralSecondaryAlt);
}
