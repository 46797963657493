.navigationContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}
.NavigationHeader {
  align-items: center;
  display: flex;
  color: var(--paletteThemePrimary);
  padding: 4px;
  cursor: pointer;
  border-bottom: 1px solid var(--semanticColorsBodyDivider);
  .headerImage {
    padding: 12px;
    height: 40px;
  }
  .logoOverlay {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 40px;
  }
  font-size: 16px;
}

.openInNewTabIcon {
  padding-bottom: 4px;
  font-size: 10px;
}

.themeSwitchPanel {
  margin: 0 12px 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.themeSwitch {
    margin: 0;
    cursor: pointer;
    opacity: 0.7;
}

.themeSwitch:hover {
    opacity: 1;
}

:global(.darkTheme) .NavigationHeader {
  color: var(--paletteThemeLight);
}
