.ms-Nav-groupContent {
  /*CSS transitions*/
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  /*CSS transforms*/
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  /*CSS animations*/
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  /*CSS transitions*/
  transition-property: none !important;
  /*CSS transforms*/
  transform: none !important;
  /*CSS animations*/
  animation: none !important;
}

.adminPage {
  padding: 10px 15px;
}

.listIcon {
  vertical-align: middle;
  max-height: 16px;
  max-width: 16px;
  font-size: 16px;
}

.pageCommandBar {
  border-bottom: 1px solid #ddd;
}

.labelButton {
  height: 25px;
}

.textGuid {
  min-width: 300px;
}

.documentListIcon {
  vertical-align: middle;
  max-height: 16px;
  max-width: 16px;
  font-size: 16px;
}

.documentListIconError {
  vertical-align: middle;
  max-height: 16px;
  max-width: 16px;
  font-size: 16px;
  color: #ff0000;
  font-weight: bold;
}

.documentListIconSuccess {
  vertical-align: middle;
  max-height: 16px;
  max-width: 16px;
  font-size: 16px;
  color: #00bb00;
  font-weight: bold;
}

.dashboardCardListWrapper {
  min-height: 300px;
  max-height: 40vh;
  overflow: hidden auto;
}

.dashboardCardWrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  padding: 10px;
  margin: 10px;
}

.exceptionsCausingRequestFailureCard {
  width: 600px;
}

.failedOperationsCard {
  width: 600px;
}

.top10FailedRequestsCard {
  width: 600px;
}

.responseTimeTrendCard {
  max-width: 100%;
}

.chartTooltipWrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  padding: 10px;
  background-color: #fff;
}
